<template>
    <div>
        <pm-Card>
            <template v-slot:title>
                Login e dados pessoais
            </template>
            <template v-slot:subtitle>
                Insira os dados abaixo
            </template>
            <template v-slot:content>
                <div class="p-fluid formgrid grid">
                    <div class="field col-12 md:col-2">
                        <label for="firstname">Email:</label>
                        <pm-InputText id="email" @change="validateForm" :style="greenColor" v-model="info.email" v-on:blur="verificarEmail" :class="{'p-invalid': validationErrors.email && submitted}"/>
                        <small v-show="validationErrors.email && submitted" class="p-error">O email é obrigatório!</small>
                        <small v-show="emailInvalidoJaExiste" class="p-error">O Email digitado já está em uso!</small>
                    </div>
                    <div class="field col-12 md:col-3">
                        <label for="senha">Senha:</label>
                        <pm-Password v-model="info.senha"  weakLabel='Fraca' mediumLabel='Média' strongLabel='Forte' toggleMask :class="{'p-invalid': validationErrors.senha && validationErrors.senhaObrigatoria && submitted}"></pm-Password>
                        <small v-show="validationErrors.senha && submitted" class="p-error">As Senhas não coincidem!</small>
                        <small v-show="validationErrors.senhaObrigatoria && submitted" class="p-error">A Senha é obrigatória!</small>
                    </div>
                    <div class="field col-12 md:col-3">
                        <label for="senha">Confirmar senha</label>
                        <pm-Password v-model="info.confirmarSenha" weakLabel='Fraca' mediumLabel='Média' strongLabel='Forte' toggleMask :class="{'p-invalid': validationErrors.confirmarSenha && submitted}"></pm-Password>
                        <small v-show="validationErrors.confirmarSenha && submitted" class="p-error">Senhas não coincidem!</small>
                    </div>
                    <div class="field col-12 md:col-2">
                        <label for="funcao">Função:</label>
                        <pm-Dropdown v-model="info.funcao"   @change="validateForm" :options="funcaoALL" optionLabel="nome" placeholder="Selecione uma função" :class="{'p-invalid': validationErrors.funcao && submitted}" />
                        <small v-show="validationErrors.funcao && submitted" class="p-error">A função é obrigatória!</small>

                    </div>
                    <div class="field col-12 md:col-2">
                        <label for="senha">Adicionar celular:</label>
                        <pm-InputText id="celular" v-model="celular1" placeholder="Digite o número"  @keyup.enter="adicionarTelefone(1)"  v-mask="{mask: '(NN) NNNNN-NNNN'}"/>
                    </div>
                </div>


                <pm-DataTable :value="info.telefone"  v-if="info.telefone.length" dataKey="nome" :rowHover="true" filterDisplay="menu" v-model:filters="filters"
                    :globalFilterFields="['nome']" responsiveLayout="scroll">
                    <template #header>
                    Você pode editar ou deletar um número
                    </template>
                    <pm-Column field="nome" sortable header="Celular" style="font-size: 14px;vertical-align:middle !important">
                    </pm-Column>
                    <pm-Column field="nome"  headerStyle="width: 4rem; text-align: center" bodyStyle="text-align: center; overflow: visible;width: 357px;vertical-align:middle !important">
                        <template #body="{data}">
                            <pm-Button type="button" @click="oTelefoneSeraCadastradoOuEditado(2, data.nome)" icon="pi pi-pencil"
                            label="Editar" title="Editar" class="p-button-sm btn-color"></pm-Button>
                            <pm-Button title="Apagar" type="button" @click="deletarCelular(data)" label="Apagar"
                            icon="pi pi-times-circle" class="p-button-danger p-button-sm btn-color" style="margin-left:5px"></pm-Button>
                        </template>
                    </pm-Column>
                </pm-DataTable>
                <br>

            </template>
            <template v-slot:footer>
                <div class="grid grid-nogutter justify-content-between">
                    <pm-Button label="Voltar" @click="voltar()" icon="pi pi-angle-left" iconPos="right" style="margin-right:20px;"/>
                    <pm-Button label="Anterior" @click="prevPage()" icon="pi pi-angle-left" />
                    <pm-Button label="Próximo" @click="nextPage()" icon="pi pi-times-circle" iconPos="right" />
                </div>
            </template>
        </pm-Card>


        <pm-Dialog v-model:visible="display2">
            <template #header>
                <h3>Editar  número de telefone</h3>
            </template>

            <div class="field col-12 md:col-6">
                <label for="numero">Número</label>
                <pm-InputText id="numero" v-model="celular3"  @keyup.enter="adicionarTelefone(2)"   v-mask="{mask: '(NN) NNNNN-NNNN', model: 'celular3'}" />
            </div>

            <template #footer>
                <pm-Button v-if="cadastrarOuEditarCelular == 2"  label="Editar" icon="pi pi-check" autofocus  @click="adicionarTelefone(2)"/>
            </template>
        </pm-Dialog>

    </div>

</template>


<script>

import { Funcao } from "@/class/funcao.js";
import { Permissoes } from "@/class/permissoes.js";
import { Telefone } from "@/class/telefone.js";
import { Servidores } from "@/class/servidores.js";
import { Verificar } from "@/class/verificar.js";

export default {
    props: {
        dadosPessoa: { type: Array, default: () => [] },
        pg: {}
    },
    data () {
        return {
            emailInvalidoJaExiste:'',
            display2:false,
            info: {
                nome: '',
                nomeMae: '',
                nomePai: '',
                possuiDeficiencia: false,
                deficiencia: '',
                nee:false,
                observacao:'',
                email:'',
                senha:'',
                matricula:null,
                rg:'',
                dataEmissao:'',
                cpf:'',
                dataNascimento:'',
                sexo:'',
                cep:'',
                logradouro:'',
                numero:'',
                complemento:'',
                bairro:'',
                cidade:'',
                estado:'',
                telefone:[],
                confirmarSenha:'',
                funcao:''
            },
            funcaoALL: [],
            modulos: [],
            celular1:'',
            celular2:'',
            celular3:'',
            cadastrarOuEditarCelular:-1,
            indexNumeroEditado:-1,
            submitted: false,
            validationErrors: {},
            greenColor:'',
            visualizar:[],
            cadastrar:[],
            editar:[],
            deletar:[],
        }
    },
    methods: {
        voltar() {
            this.$router.push({ name: "coordenacao-servidores" , params: { pg: this.pg }});
        },
        async verificarEmail() {
            if (this.info.email == '' || this.info.email == null) {
                this.emailInvalidoJaExiste = false;
                this.greenColor = '';
                return false;
            }

            let data = await Verificar.verificarJaExisteEmail(this.info.email);
            try {
                if (this.info.id == undefined) {
                    if (data.data) {
                        this.emailInvalidoJaExiste = true;
                        this.greenColor = "border-color: #f44336;";
                        return false;
                    }
                    else {
                        this.greenColor = "border-color: #42d36f;";
                        this.emailInvalidoJaExiste = false;
                        return true;
                    }
                }
                else if  (this.info.id != undefined && this.info.email !== this.info.emailDele) {
                    if (data.data) {
                        this.emailInvalidoJaExiste = true;
                        this.greenColor = "border-color: #f44336;";
                        return false;
                    }
                    else {
                        this.greenColor = "border-color: #42d36f;";
                        this.emailInvalidoJaExiste = false;
                        return true;
                    }
                }
                else {
                    this.emailInvalidoJaExiste = false;
                    this.greenColor = '';
                }
            }
            catch(e) {
                this.emailInvalidoJaExiste = false;
                this.greenColor = '';
            }
        },
        validateForm() {
            if (this.info.id == undefined) {
                if (!this.info.senha.trim())
                    this.validationErrors['senhaObrigatoria'] = true;
                else
                    delete this.validationErrors['senhaObrigatoria'];
            }

            if (!this.info.email.trim())
                this.validationErrors['email'] = true;
            else
                delete this.validationErrors['email'];

            var ok = this.info.senha === this.info.confirmarSenha;
            if (!ok)
                this.validationErrors['senha'] = true;
            else
                delete this.validationErrors['senha'];

            if (!ok)
                this.validationErrors['confirmarSenha'] = true;
            else
                delete this.validationErrors['confirmarSenha'];

            if (this.info.funcao  == '' || this.info.funcao == undefined)
                this.validationErrors['funcao'] = true;
            else
                delete this.validationErrors['funcao'];
            return !Object.keys(this.validationErrors).length;
        },
        nextPage() {
            this.submitted = true;
            if (this.validateForm() && this.emailInvalidoJaExiste != true)
                this.$emit('nextPage', {info: this.info});
        },
        prevPage() {
            this.submitted = true;
            if (this.validateForm() && this.emailInvalidoJaExiste != true)
                this.$emit('prevPage', {info: this.info});
        },
        isNumber(e) {
            let char = String.fromCharCode(e.keyCode); // Get the character
            if(/^[0123456789]+$/.test(char)) return true; // Match with regex
            else e.preventDefault(); // If not match, don't add to input text
        },
        oTelefoneSeraCadastradoOuEditado(acao, celular) {
            if (acao == 2) {
                for (var i = 0; i < this.info.telefone.length; i++) {
                    if (celular === this.info.telefone[i].nome) {
                        this.indexNumeroEditado = i;
                        this.celular2 = this.info.telefone[i];
                        this.celular3 = this.info.telefone[i].nome;
                        break;
                    }
                }
            }
            else {
                this.celular1 = '';
            }
            this.cadastrarOuEditarCelular = acao;
            this.display2 = true;
        },
        async adicionarTelefone(acao) {
            let telVerificar = "";
            if (acao == 1){
                telVerificar = this.celular1;
            }else{
                telVerificar = this.celular3;
            }

            telVerificar = telVerificar.replace(/[^0-9]/g, '');
            let telefone_form;

            for (let index = 0; index < telVerificar.length; index++) {
                if(index>0){
                    if(index<11){
                       telefone_form = `${telefone_form}${telVerificar[index]}`;
                    }
                }else{
                    telefone_form = `${telVerificar[index]}`;
                }
            }
            telVerificar = telefone_form;

            if (telVerificar.length < 9) {
                return false;
            }

            //verificar se o número ja existe no banco
            let jaExisteTelefone = await Telefone.obtemTelefone(telVerificar);
            if (jaExisteTelefone.data.telefone != undefined) {
                alert("O número digitado já existe na base de dados!");
                return false;
            }//Se tiver cadastrando
            if (this.info.id == undefined) {
                for (var i = 0; i < this.info.telefone.length; i++) {
                    if (telVerificar === this.info.telefone[i]) {
                        alert("O número inserido já foi adicionado a lista!");
                        return false;
                    }
                }
                if (acao == 1) {
                    this.info.telefone.push({nome: telVerificar});
                    this.celular1 = '';
                }
                else {
                    this.info.telefone[this.indexNumeroEditado].nome = telVerificar;
                    this.celular3 = '';
                }
                this.display2 = false;
            }//Se tiver editando
            else {
                if (acao == 1) {
                    let data = await Telefone.addTelefone(this.info.login_id, telVerificar);
                    this.celular1 = '';
                    this.atualizarTelefones();
                }
                else {
                    let novo = {
                        id: this.celular2.id,
                        telefone: telVerificar
                    };

                    let data = await Telefone.editarTelefone(novo);
                    this.celular2 = '';
                    this.atualizarTelefones();
                }
                this.display2 = false;
            }
        },
        async buscarFuncaoALL() {
            try {
            let data = await Funcao.obtemTodos();
                for (const el of data.data) {
                    let novo = {
                    id: el.id,
                    nome:el.nome,
                    };
                    if (el.id < 2 || el.id == 6 || el.id == 2 || el.id == 7){
                    continue;
                    }
                    this.funcaoALL.push(novo);
                    this.funcaoALL.reverse();
                };
            } catch (e) {
            }
        },
        async deletarCelular(celular) {
            //Se for cadastrar
            if (this.info.id == undefined) {
                if (window.confirm("Você realmente deseja apagar o número selecionado?")) {
                    for (var i = 0; i < this.info.telefone.length; i++) {
                        if (celular.nome === this.info.telefone[i].nome) {
                            this.info.telefone.splice(i, 1);
                            break;
                        }
                    }
                }
            }//Se for editar
            else {
                if (window.confirm("Você realmente deseja apagar o número selecionado?")) {
                    let data = await Telefone.removerTelefone(celular.id);
                    this.atualizarTelefones();
                }
            }
        },
        async atualizarTelefones() {
            const data = await Servidores.obtemUm(this.info.id);
            let result = data.data;
            this.info.telefone = [];
            for (const el of result[0].telefone) {
                this.info.telefone.push({id: el.id, nome: el.telefone});
            }
        },
        async modulo(tipo,mod,check){
          var check_number;
          if(check){
            check_number = 1;
          }else{
            check_number = 0;
          }

          if(tipo == 'visualizar'){
            let payload = {
              'login_id': this.info.login_id,
              'funcao_id': this.info.funcao.id,
              'permissao_modulo_id': mod,
              'visualizar': check_number,
            };

            let tipo_env = 1;
            let data = await Permissoes.editarPermissaoUsuarioEspecial(tipo_env,payload)
          }
          if(tipo == 'cadastrar'){
            let payload = {
              'login_id': this.info.login_id,
              'funcao_id': this.info.funcao.id,
              'permissao_modulo_id': mod,
              'cadastrar': check_number,
            };

            let tipo_env = 2;
            let data = await Permissoes.editarPermissaoUsuarioEspecial(tipo_env,payload)
          }
          if(tipo == 'editar'){
            let payload = {
              'login_id': this.info.login_id,
              'funcao_id': this.info.funcao.id,
              'permissao_modulo_id': mod,
              'editar': check_number,
            };

            let tipo_env = 3;
            let data = await Permissoes.editarPermissaoUsuarioEspecial(tipo_env,payload)
          }
          if(tipo == 'deletar'){
            let payload = {
              'login_id': this.info.login_id,
              'funcao_id': this.info.funcao.id,
              'permissao_modulo_id': mod,
              'deletar': check_number,
            };

            let tipo_env = 4;
            let data = await Permissoes.editarPermissaoUsuarioEspecial(tipo_env,payload)
          }

        }
    },
    async beforeMount() {
        await this.buscarFuncaoALL();
        this.info.nome =   (this.dadosPessoa.nome != undefined ? this.dadosPessoa.nome : this.info.nome);
        this.info.sobrenome =   (this.dadosPessoa.sobrenome != undefined ? this.dadosPessoa.sobrenome : this.info.sobrenome);
        this.info.possuiDeficiencia = this.dadosPessoa.possuiDeficiencia;
        this.info.deficiencia =  this.dadosPessoa.deficiencia;
        this.info.observacao = this.dadosPessoa.observacao;
        this.info.nee = this.dadosPessoa.nee;
        this.info.email =  this.info.email =  (this.dadosPessoa.email != undefined ? this.dadosPessoa.email : this.info.email);
        this.info.emailPadrao =  this.dadosPessoa.emailPadrao;
        this.info.senha =  (this.dadosPessoa.senha != undefined ? this.dadosPessoa.senha : this.info.senha);
        this.info.confirmarSenha =  (this.dadosPessoa.confirmarSenha != undefined ? this.dadosPessoa.confirmarSenha : this.info.confirmarSenha);
        this.info.rg =  this.dadosPessoa.rg;
        this.info.rgDataEmissao = (this.dadosPessoa.rgDataEmissao != undefined ? this.dadosPessoa.rgDataEmissao : this.info.rgDataEmissao);
        this.info.cpf =  this.dadosPessoa.cpf;
        this.info.cpfDele = (this.dadosPessoa.cpfDele != undefined ? this.dadosPessoa.cpfDele : '');        this.info.dataNascimento =  (this.dadosPessoa.dataNascimento != undefined ? this.dadosPessoa.dataNascimento : this.info.dataNascimento);
        this.info.cep =  (this.dadosPessoa.cep != undefined ? this.dadosPessoa.cep : this.info.cep);
        this.info.estado =  this.dadosPessoa.estado;
        this.info.cidade =  this.dadosPessoa.cidade;
        this.info.bairro =  this.dadosPessoa.bairro;
        this.info.logradouro =  this.dadosPessoa.logradouro;
        this.info.numero =  this.dadosPessoa.numero;
        this.info.complemento =  this.dadosPessoa.complemento;
        this.info.telefone =  (this.dadosPessoa.telefone == undefined ? [] : this.dadosPessoa.telefone);
        this.info.sexo =  (this.dadosPessoa.sexo != undefined ? this.dadosPessoa.sexo : this.info.sexo );
        this.info.foto =  this.dadosPessoa.foto;
        this.info.id =  (this.dadosPessoa.id != undefined ? this.dadosPessoa.id : this.info.id);
        this.dadosPessoa.jaBuscou = true;
        this.info.matricula =  this.dadosPessoa.matricula;
        this.info.escolaridade =  this.dadosPessoa.escolaridade;
        this.info.funcao = (this.dadosPessoa.funcao != undefined ? this.dadosPessoa.funcao : this.info.funcao);
        this.info.login_id = this.dadosPessoa.login_id;
        this.info.emailDele = this.dadosPessoa.emailDele;



        let data = await Permissoes.obtemUsuarioEspecialpermissao(this.info.login_id,this.info.funcao.id);
        this.modulos = data.data;

    },
}
</script>
